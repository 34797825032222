@import "variables.scss";

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: "Montserrat", "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.575;
  color: #2f2f2f;
  font-size: 14px;

  @media print {
    font-family: "Lato", sans-serif;

    @page {
      margin: 0mm 0mm 0mm 0mm;
      size: calc(210mm * 1.04) calc(297mm * 1.04);
      max-width: calc(210mm * 1.04);
      max-height: calc(297mm * 1.04);
    }
  }
}

p {
  margin: 10px 0;
}
