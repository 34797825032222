$sizes: xxs xs sm md lg xl 2xl 3xl 4xl 5xl 6xl 7xl 8xl 9xl 10xl;
$spacing: xxs xs sm md lg xl 2xl 3xl 4xl 5xl 6xl 7xl 8xl 9xl 10xl;

:root {
    --bgColor-primary: #255f85;
    --color-primary: white;
    --bdColor-primary: #ccc;

    --bgColor-secondary: #58a4b0;
    --color-secondary: rgb(255, 255, 255);
    --bdColor-secondary: #ccc;

    --bgColor-tertiary: #a9bcd09e;
    --color-tertiary: rgb(48, 48, 48);
    --bdColor-tertiary: #ccc;

    --bg-hover: linear-gradient(#00000012, #00000012);

    --spacing-scale: 2px;
    --round-scale: 3px;

    $spacing-scale: 2px;
    @for $i from 1 through length($spacing) {
        $c: nth($spacing, $i);
        --spacing-#{$c}: #{$i*$spacing-scale};
    }

    $spacing-scale: 2px;
    @for $i from 1 through length($spacing) {
        $c: nth($spacing, $i);
        --round-#{$c}: #{$i*$spacing-scale};
    }

    $font-scale: 4px;
    @for $i from 1 through length($spacing) {
        $c: nth($spacing, $i);
        --fontSize-#{$c}: #{$i*$font-scale - 2px};
    }

    @for $i from 1 through 200 {
        --size-#{$i*10}: #{$i*2px};
    }

    // --bd: 1px solid #ccc;
    // --bd-primary: 1px solid var(--bgColor-primary);
    // --bd-secondary: 1px solid var(--bgColor-secondary);
    // --bd-tertiary: 1px solid var(--bgColor-tertiary);

    // --bs: 0px 0px 3px 0px #0000002e;
    // --bs-md: 0px 0px 3px 1px #00000033;
}
